import React, { useEffect, useState } from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import { format } from "date-fns-tz";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import styles from "./AuctionSteps.module.scss";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import { AuctionPartial } from "../../../../../../store/auctionModal/actions";
import AuctionButtonsContainer from "../internal/AuctionButtonsContainer";
import {
  getCurrencyLocaleValue,
  sendToast,
} from "../../../../../../utils/helpers";
import { useAppDispatch } from "../../../../../../store";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
} from "../../../../../../store/user/selectors";
import { User } from "../../../../../../types/User";
import {
  patchAuction,
  saveAuctionPartial,
  ScheduledAuction,
} from "../../../../../../store/auctions/actions";
import {
  hasSuccessfullyPublished,
  recentlyCreatedAuction,
  successfullyUpdatedAuction,
} from "../../../../../../store/auctions/selectors";
import { event } from "../../../../../../lib/ga";
import { updateAuction } from "../../../../../../store/auctionModal/reducer";
import { getUserInfo } from "../../../../../../store/user/actions";
import { Market } from "../../../../../../types/Market";

const InstagramIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3166 21.7255C27.3166 24.8006 24.8528 27.3166 21.8417 27.3166H8.16084C5.15028 27.3166 2.68652 24.8006 2.68652 21.7255V8.27766C2.68652 5.20247 5.15028 2.68652 8.16084 2.68652H21.8417C24.8528 2.68652 27.3166 5.20247 27.3166 8.27766V21.7255ZM22.0924 0H7.90762C3.558 0 0 3.6338 0 8.07601V21.924C0 26.3662 3.558 30 7.90762 30H22.0924C26.442 30 30 26.3662 30 21.924V8.07601C30 3.6338 26.442 0 22.0924 0Z"
      fill="#E0E0E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9968 19.9714C12.3094 19.9714 10.1309 17.7462 10.1309 15.0016C10.1309 12.2564 12.3094 10.0319 14.9968 10.0312C17.6849 10.0312 19.8635 12.2564 19.8635 15.0016C19.8635 17.7462 17.6849 19.9714 14.9968 19.9714ZM14.9973 7.29785C10.8316 7.29785 7.45508 10.7465 7.45508 15.001C7.45508 19.2556 10.8316 22.7042 14.9973 22.7042C19.1637 22.7042 22.5402 19.2556 22.5402 15.001C22.5402 10.7465 19.163 7.29785 14.9973 7.29785Z"
      fill="#E0E0E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6838 7.05746C23.6838 7.92958 22.977 8.63641 22.1048 8.63641C21.2327 8.63641 20.5259 7.92958 20.5259 7.05746C20.5259 6.18535 21.2327 5.47852 22.1048 5.47852C22.977 5.47852 23.6838 6.18535 23.6838 7.05746Z"
      fill="#E0E0E0"
    />
  </svg>
);

const PostYourAuction = () => {
  const dispatch = useAppDispatch();
  const successfullyUpdated = useSelector((state) =>
    successfullyUpdatedAuction(state)
  ) as boolean;
  const createdAuction = useSelector((state) =>
    recentlyCreatedAuction(state)
  ) as ScheduledAuction;
  const success = useSelector((state) =>
    hasSuccessfullyPublished(state)
  ) as boolean;
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];

  const [localLoading, setLocalLoading] = useState(false);

  const [selectedMarket, setSelectedMarket] = useState("");

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  useEffect(() => {
    if (availableMarkets.length > 0) {
      const selectedAuctionMarket = availableMarkets.find(
        (market) => market.id === auction.market_id
      );
      if (selectedAuctionMarket) {
        setSelectedMarket(selectedAuctionMarket.name);
      }
    }
  }, [availableMarkets, auction]);

  useEffect(() => {
    if (successfullyUpdated) {
      event({
        action: "scheduled_auction_on_update",
        params: {},
      });
      if (isEditing) {
        navigate(`/dashboard/auctions/${auction.id}/success/`);
      } else {
        navigate("/dashboard/auctions/create/success/");
      }
    }
  }, [successfullyUpdated]);

  useEffect(() => {
    if (success && createdAuction && createdAuction.progress !== "draft") {
      event({
        action: "scheduled_auction",
        params: {},
      });
      if (isEditing) {
        navigate(`/dashboard/auctions/${auction.id}/success/`);
      } else {
        navigate("/dashboard/auctions/create/success/");
      }
    }
  }, [success, createdAuction]);

  const getAuctionDate = () => {
    if (!auction || !auction.starts_at || !auction.ends_at) {
      return "";
    }
    const formattedStartDate = format(
      new Date(auction.starts_at),
      "MMM dd, yyyy"
    );
    const formattedEndDate = format(new Date(auction.ends_at), "MMM dd, yyyy");
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const onSubmit = () => {
    if (localLoading) {
      return;
    }
    if (!auction.item.files || auction.item.files.length === 0) {
      sendToast("Please add an image in order to publish", { type: "warning" });
      return;
    }
    // Handle common
    setLocalLoading(true);
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    const formattedStartDate = format(
      new Date(auction.starts_at),
      "MMM, dd, yyyy h:mm aa zzz"
    );
    const formattedEndDate = format(
      new Date(auction.ends_at),
      "MMM, dd, yyyy h:mm aa zzz"
    );
    auct.description.details = `\nStart Date: ${formattedStartDate}\nEnds At: ${formattedEndDate}\nStarting Bid Price:
     ${getCurrencyLocaleValue(auction.currency, auction.item.price)}
    \nBid Increment:
     ${getCurrencyLocaleValue(auction.currency, auction.item.increment)}
    \nCurrency: ${auction.currency}\n`;
    auct.progress = "schedule";
    Object.preventExtensions(auct);
    const auctionPartial = {
      userInfo,
      auction: auct,
    } as AuctionPartial;
    if (isEditing) {
      dispatch(updateAuction(auct));
      dispatch(patchAuction(auctionPartial));
    } else {
      dispatch(saveAuctionPartial(auctionPartial));
    }
    setLocalLoading(false);
  };

  const getUrl = () => auction.item.files[0].url;
  const hasImages =
    auction &&
    auction.item &&
    auction.item.files &&
    auction.item.files.length > 0;
  return (
    <div className={cn([styles.creationContainer])}>
      <div className={cn([styles.mobileOnly, "mb-4"])}>
        <h3>@{selectedMarket}</h3>
        <h2>
          Your Auction is
          <br /> Ready to go.
        </h2>
      </div>
      <div className={styles.creationImageContainer}>
        <div className={styles.auctionPreviewImageContainer}>
          {/* TODO: image */}
          {hasImages && (
            <Imgix
              src={`${getUrl()}?auto=format`}
              height={570}
              width={570}
              htmlAttributes={{
                alt: "Auction Image",
                src: `${getUrl()}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          )}
        </div>
      </div>
      <div className={cn([styles.textContainer, styles.previewCopyContainer])}>
        <div className={styles.desktopOnly}>
          <h3>@{selectedMarket}</h3>
          <h2>
            Your Auction is
            <br /> Ready to go.
          </h2>
          <p className={styles.autoStartCopy}>
            <strong>Starts At</strong>:{" "}
            {auction.starts_at &&
              format(new Date(auction.starts_at), "MMM, dd, yyyy h:mm aa zzz")}
          </p>
          <p>
            <strong>Ends At</strong>:{" "}
            {auction.ends_at &&
              format(new Date(auction.ends_at), "MMM, dd, yyyy h:mm aa zzz")}
          </p>
        </div>
        <div
          className={cn([
            styles.auctionPreviewDescriptionContainer,
            styles.mobileOnly,
          ])}
        >
          <div className="flex flex-row justify-between w-full items-start mb-0">
            <h4>{auction.name}</h4>
            <InstagramIcon />
          </div>
          <div className="flex flex-row justify-between">
            <p>{getAuctionDate()}</p>
          </div>
        </div>
        <div
          className={cn([
            styles.learnContent,
            styles.noBorderTop,
            styles.desktopOnly,
          ])}
        >
          <AuctionButtonsContainer
            loading={localLoading}
            disabled={localLoading}
            nextTitle="CONFIRM"
            nextPage={() => onSubmit()}
          />
        </div>
      </div>
      <div
        className={cn([
          styles.learnContent,
          styles.noBorderTop,
          styles.mobileOnly,
        ])}
      >
        <AuctionButtonsContainer
          loading={localLoading}
          disabled={localLoading}
          nextTitle="CONFIRM"
          nextPage={() => onSubmit()}
        />
      </div>
    </div>
  );
};

export default PostYourAuction;
