import React from "react";
import { navigate } from "gatsby";
import { AuthProvider } from "../../../../components/Auth/auth";
import DashboardLayout from "../../../../components/DashboardLayout";
import SimpleSpring from "../../../../components/Transitions/transitions";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import PostYourAuction from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/steps/PostYourAuction";

const AuctionCreateStep8 = ({ location }) => (
  <AuthProvider>
    <SimpleSpring location={location}>
      <DashboardLayout
        hideFooter
        style={{ paddingTop: 0 }}
        hideNav
        title="Bidddy | Create New Auction - Post Auction"
      >
        <AuctionCreationNav
          onBack={() => navigate("/dashboard/auctions/create/preview/")}
          step={4}
          totalSteps={6}
          title="Post Your Auction"
        />
        <PostYourAuction />
      </DashboardLayout>
    </SimpleSpring>
  </AuthProvider>
);

export default AuctionCreateStep8;
